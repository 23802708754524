svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6.944444rem;
  margin-left: -11.111111rem;
}

.message-box {
  height: 5.555556rem;
  width: 10.555556rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2.777778rem;
  margin-left: 1.388889rem;
  color: #fff;
  font-weight: 300;
}

.message-box h1 {
  font-size: 1.666667rem;
  line-height: 1.277778rem;
  margin-bottom: 1.111111rem;
}

.buttons-con .action-link-wrap {
  margin-top: 1.111111rem;
}

.buttons-con .action-link-wrap a {
  background: #68c950;
  padding: 0.222222rem 0.694444rem;
  border-radius: 0.111111rem;
  color: #fff;
  font-weight: bold;
  font-size: 0.388889rem;
  transition: all .3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-right: 0.277778rem;
}

.buttons-con .action-link-wrap a:hover {
  background: #5a5c6c;
  color: #fff;
}

#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-5 {
  -webkit-animation: float 1.8s infinite ease-in-out alternate;
  animation: float 1.8s infinite ease-in-out alternate;
}

#Polygon-2 {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

#Polygon-3 {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

#Polygon-4 {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

#Polygon-5 {
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
}

@-webkit-keyframes float {
  100% {
    -webkit-transform: translateY(0.555556rem);
    transform: translateY(0.555556rem);
  }
}

@keyframes float {
  100% {
    -webkit-transform: translateY(0.555556rem);
    transform: translateY(0.555556rem);
  }
}

@media (max-width: 750px) {
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -6.944444rem;
    margin-left: -5.277778rem;
  }

  .message-box {
    top: 50%;
    left: 50%;
    margin-top: -2.777778rem;
    margin-left: -5.277778rem;
    text-align: center;
  }
}